import banner from './resources/images/banner.png';
// import logoTravel from './resources/images/logo-travel.png';
// import logoHanoi from './resources/images/logo-hanoi.jpg';
import adLeft from './resources/images/ad-left.jpg';
import adRight from './resources/images/ad-right.jpg';
import adfooter1 from './resources/images/ad-footer1.jpg';
// import adfooter2 from './resources/images/ad-footer2.png';
// import adfooter3 from './resources/images/ad-footer3.png';
// import flag from './resources/images/flag.png';
// import footer from './resources/images/footer.png';
import React, { Component } from 'react';
import moment from 'moment';
import './App.css';

import { getPeriods, getStatisticToday, getStatistics } from './services/services';
import { AxiosResponse } from 'axios';

const beforeStartDownAnnounceDate = moment().format('YYYY-MM-DD 22:15:00');
const afterStartDownAnnounceDate = moment().format('YYYY-MM-DD 22:15:59');
const startDownAnnounceDate = moment().format('YYYY-MM-DD 22:16:00');
const endDownAnnouneDate = moment().format('YYYY-MM-DD 22:19:59');
const downAnnounceDate = moment().format('YYYY-MM-DD 22:20:00');

const beforeStartUpAnnounceDate = moment().format('YYYY-MM-DD 22:20:01');
const afterStartUpAnnounceDate = moment().format('YYYY-MM-DD 22:20:59');
const startUpAnnounceDate = moment().format('YYYY-MM-DD 22:21:00');
const endUpAnnouneDate = moment().format('YYYY-MM-DD 22:29:59');
const upAnnounceDate = moment().format('YYYY-MM-DD 22:30:00');

const randomNumber = () => {
  const rand = Math.floor(Math.random() * 10);
  // const two = Math.floor(Math.random() * 10);
  // const three = Math.floor(Math.random() * 10);
  // const four = Math.floor(Math.random() * 10);
  // const five = Math.floor(Math.random() * 10);
  return rand + "";
}

const getLoading = () => {
  return 'loading';
}

const imageLoading = () => {
  const images = [];
  for (let i = 0; i < 5; i++) {
    const image = <img
      style={{ marginRight: 2 }}
      src={require('./resources/images/loading.gif')}
      width={50}
      height={50}
      alt='number'
    />;

    images.push(image);
  }
  return images;
}

class App extends Component {

  _isMounted = false;
  timer: any = null;
  downTimer: any = null;
  upTimer: any = null;

  state = {
    isMobile: false,
    statistics: [],
    beforeStartDown: false,
    beforeStartUp: false,
    todayDate: moment().format('YYYY-MM-DD HH:mm:ss'),
    announceStartDate: moment().format('YYYY-MM-DD 22:10:00'),
    announceEndDate: moment().format('YYYY-MM-DD 22:30:00'),
    onePrizes: {
      one: getLoading(),
      two: getLoading(),
      three: getLoading(),
      four: getLoading(),
      five: getLoading()
    },
    twoPrizes: {
      one: getLoading(),
      two: getLoading(),
      three: getLoading(),
      four: getLoading(),
      five: getLoading()
    },
    // onePrizes: {
    //   one: randomNumber(),
    //   two: randomNumber(),
    //   three: randomNumber(),
    //   four: randomNumber(),
    //   five: randomNumber(),
    // },
    // twoPrizes: {
    //   one: randomNumber(),
    //   two: randomNumber(),
    //   three: randomNumber(),
    //   four: randomNumber(),
    //   five: randomNumber(),
    // },
    oldAnnounce: false,
    newAnnounce: false,
    downAnnounce: false,
    oldUpAnnounce: false,
    downRelease: false,
    upAnnounce: false,
  };

  componentWillUnmount(): void {
    this._isMounted = false;
    // clearInterval(this.timer);
    clearInterval(this.downTimer);
    clearInterval(this.upTimer);
    window.removeEventListener("resize", this.resizeWindow);
  }

  componentDidMount(): void {
    this._isMounted = true;
    this.getAllStatistics();
    window.addEventListener("resize", this.resizeWindow);
    this.resizeWindow();

    this.setTimer();
  }

  setTimer = () => {
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.setState({ todayDate: moment().format('YYYY-MM-DD HH:mm:ss') });
        this.announce();
      }, 1000);
    }
  }

  announce = () => {
    const { announceStartDate, announceEndDate, todayDate } = this.state;

    if (moment(todayDate).toDate().getTime() < moment(announceStartDate).toDate().getTime()) {
      this.beforeAnnonce();
    } else if (
      moment(todayDate).toDate().getTime() >= moment(announceStartDate).toDate().getTime()
      && moment(todayDate).toDate().getTime() <= moment(announceEndDate).toDate().getTime()
    ) {
      this.setData();
    } else {
      this.afterAnnounce();
    }
  }

  beforeAnnonce = async () => {
    const { todayDate, oldAnnounce } = this.state;
    if (!oldAnnounce) {
      const dateNow = moment(todayDate).add(-1, 'd').format("YYYY-MM-DD 22:30:00");
      const period = await getPeriods(dateNow, 'both', '28');
      const up = period.data.up6;
      const down = period.data.down6;

      this.setState({
        onePrizes: {
          one: up[0],
          two: up[1],
          three: up[2],
          four: up[3],
          five: up[4],
        },
        twoPrizes: {
          one: down[0],
          two: down[1],
          three: down[2],
          four: down[3],
          five: down[4],
        },
        oldAnnounce: true
      })
    }
  }

  afterAnnounce = async () => {

    const { newAnnounce } = this.state;
    if (!newAnnounce) {
      const statisticToday = await getStatisticToday();

      const up = statisticToday.data.up6;
      const down = statisticToday.data.down6;

      this.setState({
        onePrizes: {
          one: up[0],
          two: up[1],
          three: up[2],
          four: up[3],
          five: up[4],
        },
        twoPrizes: {
          one: down[0],
          two: down[1],
          three: down[2],
          four: down[3],
          five: down[4],
        },
        newAnnounce: true
      })
    }

  }

  setData = async () => {
    const { todayDate, beforeStartDown, beforeStartUp, downAnnounce, oldUpAnnounce, downRelease, upAnnounce } = this.state;

    // --------------------- *** DOWN *** -------------------------
    // before start down
    if (moment(todayDate).toDate().getTime() >= moment(beforeStartDownAnnounceDate).toDate().getTime() && moment(todayDate).toDate().getTime() <= moment(afterStartDownAnnounceDate).toDate().getTime()) {
      if (!beforeStartDown) {
        this.setState({
          onePrizes: {
            one: getLoading(),
            two: getLoading(),
            three: getLoading(),
            four: getLoading(),
            five: getLoading()
          },
          twoPrizes: {
            one: getLoading(),
            two: getLoading(),
            three: getLoading(),
            four: getLoading(),
            five: getLoading()
          },
          beforeStartDown: true
        })
      }
    }

    // down random number started
    if (moment(todayDate).toDate().getTime() >= moment(startDownAnnounceDate).toDate().getTime() && moment(todayDate).toDate().getTime() <= moment(endDownAnnouneDate).toDate().getTime()) {
      if (!this.downTimer) {
        this.downTimer = setInterval(() => {
          this.setState({
            twoPrizes: {
              one: randomNumber(),
              two: randomNumber(),
              three: randomNumber(),
              four: randomNumber(),
              five: randomNumber(),
            }
          })
        }, 100);
      }
    }

    // down announce
    if (moment(todayDate).toDate().getTime() === moment(downAnnounceDate).toDate().getTime()) {
      if (this.downTimer) clearInterval(this.downTimer);
      // call api and show down
      if (!downAnnounce) {
        const dateNow = moment(todayDate).format("YYYY-MM-DD 22:30:00");
        const period = await getPeriods(dateNow, 'down', '28');
        const down = period.data.down6;

        this.setState({
          twoPrizes: {
            one: down[0],
            two: down[1],
            three: down[2],
            four: down[3],
            five: down[4]
          },
          downAnnounce: true
        });
      }
    }

    if (moment(todayDate).toDate().getTime() > moment(downAnnounceDate).toDate().getTime()) {
      if (this.downTimer) clearInterval(this.downTimer);
      // show release down
      if (!downRelease) {
        const dateNow = moment(todayDate).format("YYYY-MM-DD 22:30:00");
        const period = await getPeriods(dateNow, 'down', '28');
        const down = period.data.down6;

        this.setState({
          twoPrizes: {
            one: down[0],
            two: down[1],
            three: down[2],
            four: down[3],
            five: down[4]
          },
          downRelease: true
        });
      }
    }

    // --------------------- *** UP *** -------------------------
    if (moment(todayDate).toDate() < moment(startUpAnnounceDate).toDate()) {
      // show old up
      if (!oldUpAnnounce) {
        const dateNow = moment(todayDate).add(-1, 'd').format("YYYY-MM-DD 22:30:00");
        const period = await getPeriods(dateNow, 'up', '28');
        const up = period.data.up6;

        this.setState({
          onePrizes: {
            one: getLoading(),
            two: getLoading(),
            three: getLoading(),
            four: getLoading(),
            five: getLoading()
          },
          oldUpAnnounce: true
        });
      }

    }

    // before start up
    if (moment(todayDate).toDate() >= moment(beforeStartUpAnnounceDate).toDate() && moment(todayDate).toDate() <= moment(afterStartUpAnnounceDate).toDate()) {
      if (!beforeStartUp) {
        this.setState({
          onePrizes: {
            one: getLoading(),
            two: getLoading(),
            three: getLoading(),
            four: getLoading(),
            five: getLoading()
          },
          beforeStartUp: true
        })
      }
    }

    // up random number started
    if (moment(todayDate).toDate() >= moment(startUpAnnounceDate).toDate() && moment(todayDate).toDate() <= moment(endUpAnnouneDate).toDate()) {
      if (!this.upTimer) {
        this.upTimer = setInterval(() => {
          this.setState({
            onePrizes: {
              one: randomNumber(),
              two: randomNumber(),
              three: randomNumber(),
              four: randomNumber(),
              five: randomNumber()
            }
          })
        }, 100);
      }
    }

    // up announce
    if (moment(todayDate).toDate().getTime() === moment(upAnnounceDate).toDate().getTime()) {
      if (this.upTimer) clearInterval(this.upTimer);

      if (!upAnnounce) {
        // call api and show up 
        const statisticToday = await getStatisticToday();

        const up = statisticToday.data.up6;
        const down = statisticToday.data.down6;

        this.setState({
          onePrizes: {
            one: up[0],
            two: up[1],
            three: up[2],
            four: up[3],
            five: up[4]
          },
          twoPrizes: {
            one: down[0],
            two: down[1],
            three: down[2],
            four: down[3],
            five: down[4]
          },
          upAnnounce: true
        })
      }
    }
  }

  getAllStatistics = async () => {
    const response: AxiosResponse = await getStatistics();
    this.setState({
      statistics: response.data
    })
  }

  resizeWindow = () => {
    const width = window.innerWidth;
    if (width <= 500) {
      this.setState({ isMobile: true });
    }
    else {
      this.setState({ isMobile: false });
    }
  }

  renderDate = () => {
    const { todayDate } = this.state;

    if (moment(todayDate).toDate().getTime() >= moment(beforeStartDownAnnounceDate).toDate().getTime()) {
      return moment().format("DD/MM/YYYY");
    }

    return moment().add(-1, 'd').format("DD/MM/YYYY");
  }

  renderAnnounce = (prizes: any) => {

    if (prizes.one === 'loading') {
      return imageLoading();
    }

    return prizes.one + prizes.two + prizes.three + prizes.four + prizes.five;
  }

  render(): React.ReactNode {

    const { statistics, onePrizes, twoPrizes, isMobile } = this.state;

    return (
      <div className="container p-0">
        <div className="header d-flex flex-column justify-content-center">
          <img src={banner} className="img-fluid" alt="hanoi" />
          {/* <img src={logoTravel} className="img-fluid" alt="hanoi" /> */}
          {/* <img src={logoHanoi} className="img-fluid" alt="hanoi" /> */}
        </div>

        <div className="row">
          {!isMobile ?
            <div className={`col-md-3 col-sm-12 col-xs-12 ${!isMobile ? "pr-0" : ""}`}>
              <div className="card d-flex justify-content-center mt-0">
                <div className="card-body p-0">
                  <img src={adLeft} className="img-fluid" width={'100%'} style={{ height: 650 }} alt="" />
                </div>
              </div>
            </div> : <></>}
          <div className="col-md-6 col-sm-12 col-xs-12 p-0">
            <div style={{ border: !isMobile ? '2px solid black' : 'none' }} className="p-2 m-2">
              <table className="table table-bordered mb-0">
                <tbody>
                  <tr>
                    <td colSpan={3} className="p-1">
                      <div className="d-flex flex-row justify-content-center">
                        {!isMobile ?
                          <h4 style={{ fontWeight: 'bold', fontFamily: 'SNT-Anouvong-Regular' }} className="mb-2 mr-3">TRỰC TIẾP KẾT QUẢ XỔ SỐ ĐIỆN TOÁN NGÀY:</h4> :
                          <h5 style={{ fontWeight: 'bold', fontFamily: 'SNT-Anouvong-Regular' }} className="mb-1 mr-3 text-center">TRỰC TIẾP KẾT QUẢ XỔ SỐ ĐIỆN TOÁN NGÀY:</h5>}
                        <div className="align-self-center">
                          <h4 style={{ fontWeight: 'bold' }} className="m-0">{this.renderDate()}</h4>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontFamily: 'SNT-Anouvong-Regular', fontSize: 24, textAlign: 'center', width: 150, verticalAlign: 'middle' }}>
                      <span>Đặc biệt</span>
                    </td>
                    <td colSpan={2} style={{ padding: 0, textAlign: 'center', verticalAlign: 'middle' }}>
                      <span style={{ fontSize: 64, fontWeight: 1000, letterSpacing: 4, fontFamily: 'PSL', color: 'red' }}>{this.renderAnnounce(onePrizes)}</span>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: '#EBEBEB' }}>
                    <td style={{ fontFamily: 'SNT-Anouvong-Regular', fontSize: 24, textAlign: 'center', width: 150, verticalAlign: 'middle' }}>
                      <span>Giải nhất</span>
                    </td>
                    <td colSpan={2} style={{ padding: 0, textAlign: 'center', verticalAlign: 'middle' }}>
                      <span style={{ fontSize: 46, fontWeight: 1000, letterSpacing: 4, fontFamily: 'PSL' }}>{this.renderAnnounce(twoPrizes)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <h3 style={{ fontWeight: 'bold', fontFamily: 'SNT-Anouvong-Regular' }} className="text-center">Thống kê trước đây</h3>
                    </td>
                  </tr>
                  <tr style={{ fontSize: 20, fontFamily: 'SNT-Anouvong-Regular' }} className="text-center">
                    <td>Ngày trao giải</td>
                    <td>Giải nhất</td>
                    <td>Giải nhì</td>
                  </tr>
                  {
                    statistics?.length > 0 ? statistics.map((v: any, index: number) => {
                      if (index < 5) {
                        return <tr key={index + v.dateAnnounce} className="text-center" style={{ fontSize: 22, fontFamily: 'PSL', }}>
                          <td>{moment(v.dateAnnounce).format("DD/MM/YYYY")}</td>
                          <td>{v.up6}</td>
                          <td>{v.down6}</td>
                        </tr>
                      }
                    }) : <tr className="text-center">
                      <td colSpan={3} style={{ fontSize: 16 }}>Không có thông tin</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
            {/* {!isMobile ? <div className="card d-flex justify-content-center mt-0">
              <div className="card-body p-0">
                <img src={flag} className="img-fluid" width={'100%'} style={{ height: 194 }} alt="" />
              </div>
            </div> : <></>} */}
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 pl-0">
            <div className="card d-flex justify-content-center mt-0">
              <div className="card-body p-0">
                <img src={adRight} className="img-fluid" width={'100%'} style={{ height: 650 }} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="card-body p-0" style={{ border: 'none' }}>
          <img src={adfooter1} width={'100%'} alt="" />
        </div>

        {/*
        <div className="card-body p-0" style={{ border: 'none' }}>
          <img src={adfooter2} width={'100%'} alt="" />
        </div>

        <div className="card-body p-0" style={{ border: 'none' }}>
          <img src={adfooter3} width={'100%'} alt="" />
        </div>

         {!isMobile ? <div className="card d-flex justify-content-center mt-0">
          <div className="card-body p-0" style={{ border: 'none' }}>
            <img src={footer} width={'100%'} style={{ height: 120, marginTop: -100 }} alt="" />
          </div>
        </div> : <></>} */}
      </div>
    )
  }
}

export default App;
